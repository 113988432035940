import $ from "jquery";

const modalTrigger = () => {
    const init = () => {
        button();
    };

    const button = () => {
        const el = {
            buttonTrigger: ".js-modal-trigger",
            modal: ".js-modal",
            modalDialog: ".js-modal-dialog",
            modalCard: ".modal-card",
            close: ".close",
        };

        if ($(el.buttonTrigger).length === 0) return;
        $(el.buttonTrigger).on("click", async function () {
            let currentClient = document.querySelector("#searchClient");
            currentClient =
                currentClient.options[currentClient.selectedIndex].value;

            if (currentClient == "") {
                alert("please select client");
                return;
            }

            const response = await fetch(`/user/${currentClient}/detail`);

            if (response.ok) {
                const result = await response.json();

                console.log(result);

                setForm(result);
            }

            $(el.modal).addClass("active");

            let currentTab = $(this).attr("data-target");

            $(el.modalCard).removeClass("active");
            $(`.${currentTab}`).addClass("active");
        });

        $(el.modalDialog).on("click", function () {
            $(el.modal).removeClass("active");
            $(el.modalCard).removeClass("active");
        });

        $(el.close).on("click", function () {
            $(el.modal).removeClass("active");
            $(el.modalCard).removeClass("active");
        });
    };

    const setForm = (data) => {
        const debtorCode = document.querySelector("#contact_debtor_id");
        debtorCode.innerText = data.user.DebtorCode;

        const clientId = document.querySelector("#contact_client_id");
        clientId.value = data.user.DebtorCode;

        const phoneNumberEl = document.querySelector("#contact_phone_number");
        phoneNumberEl.value = data.user.MobileNumber;

        const faxNumberEl = document.querySelector("#contact_fax_number");
        faxNumberEl.value = data.user.FaxNumber;

        const EmailEl = document.querySelector("#contact_email");
        EmailEl.value = data.user.EmailAddress;

        const companyNameEl = document.querySelector("#contact_company_name");
        companyNameEl.value = data.user.CompanyName;

        const companyLegalFormEl = document.querySelector(
            "#contact_company_legal_form"
        );

        setSelect(
            companyLegalFormEl,
            data.userDB.user_information.company_legal_form
        );

        const chamberOfCommerceEl = document.querySelector(
            "#contact_chamber_of_commerce"
        );
        chamberOfCommerceEl.value = data.user.CompanyNumber;

        const vatNumberEl = document.querySelector("#contact_vat_number");
        vatNumberEl.value = data.user.TaxNumber;

        const userLegalFormEl = document.querySelector(
            "#contact_user_legal_form"
        );
        setSelect(
            userLegalFormEl,
            data.userDB.user_information.user_legal_form
        );

        const firstNameEl = document.querySelector("#contact_first_name");
        firstNameEl.value = data.user.SurName;

        const lastNameEl = document.querySelector("#contact_last_name");
        lastNameEl.value = data.user.Initials;

        const addressEl = document.querySelector("#contact_address");
        addressEl.value = data.user.Address;

        const postCodeEl = document.querySelector("#contact_postcode");
        postCodeEl.value = data.user.ZipCode;

        const cityEl = document.querySelector("#contact_city");
        cityEl.value = data.user.City;

        const countryEl = document.querySelector("#contact_country");
        setSelect(countryEl, data.user.Country);
    };

    const setSelect = (element, currentValue) => {
        [...element.options].forEach((option) => {
            if (option.value == currentValue) {
                option.selectedIndex = true;
                option.setAttribute("selected", true);
            }
        });
    };

    init();
};

export default modalTrigger;
