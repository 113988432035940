import $ from "jquery";

const customPricingPeriod = () => {
    const init = () => {
        menu();
    };

    const menu = () => {
        const el = {
            selectRadio: ".contract-period-radio",
            content: ".js-period-contract",
            periodCloseBtn: ".js-custom-pricing-period-delete-input",
            periodAddBtn: ".js-custom-pricing-period-add-input",
            periodInputWrapper: ".js-period-contract > .form",
        };

        // Show or hide Period input
        if ($(el.selectRadio).length === 0) return;
        $(el.selectRadio).on("click", function () {
            if ($(this).attr("id") == "contract_period_2") {
                $(el.content).addClass("active");
            } else {
                $(el.content).removeClass("active");
            }
        });

        let selectPeriodic = "";

        // Remove Period Input
        if ($(el.periodCloseBtn).length === 0) return;
        $(document).on("click", el.periodCloseBtn, function () {
            const $parent = $(this).parents().eq(3);

            $(this).closest(".group-input").remove();

            reAssignNewIndex($parent);
        });

        // Add Period Input
        if ($(el.periodAddBtn).length === 0) return;
        $(el.periodAddBtn).on("click", function () {
            $(this).data("index", $(this).data("index") + 1);

            const $index = $(this).data("index");

            const peridoicOptions = document.querySelector(
                "#custom_period_time"
            ).options;

            if (selectPeriodic == "") {
                [...peridoicOptions].forEach((option) => {
                    selectPeriodic += `<option value="${option.value}">${option.innerText}</option>`;
                });
            }

            $(`
            <div class="group-input">
                <div class="form-group number">
                    <input type="text" name="custom_price[${$index}][periods]" id="custom_period">
                </div>
                <div class="form-group month">
                    <div class="select-wrapper cstm-fit-content">
                        <select name="custom_price[${$index}][periodic]" id="custom_period_time">
                          ${selectPeriodic}
                        </select>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div class="form-group price-excl">
                    <input type="number" name="custom_price[${$index}][price_exclude]" id="custom_price_exclude" placeholder="Price excl. tax">
                    <p class="text">
                        <svg class="js-custom-pricing-period-delete-input" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 15L10 12" stroke="#951D81" stroke-width="2" stroke-linecap="round"/>
                            <path d="M14 15L14 12" stroke="#951D81" stroke-width="2" stroke-linecap="round"/>
                            <path d="M3 7H21V7C20.0681 7 19.6022 7 19.2346 7.15224C18.7446 7.35523 18.3552 7.74458 18.1522 8.23463C18 8.60218 18 9.06812 18 10V16C18 17.8856 18 18.8284 17.4142 19.4142C16.8284 20 15.8856 20 14 20H10C8.11438 20 7.17157 20 6.58579 19.4142C6 18.8284 6 17.8856 6 16V10C6 9.06812 6 8.60218 5.84776 8.23463C5.64477 7.74458 5.25542 7.35523 4.76537 7.15224C4.39782 7 3.93188 7 3 7V7Z" stroke="#951D81" stroke-width="2" stroke-linecap="round"/>
                            <path d="M10.0681 3.37059C10.1821 3.26427 10.4332 3.17033 10.7825 3.10332C11.1318 3.03632 11.5597 3 12 3C12.4403 3 12.8682 3.03632 13.2175 3.10332C13.5668 3.17033 13.8179 3.26427 13.9319 3.37059" stroke="#951D81" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        Per month
                    </p>
                </div>
            </div>
            `).insertBefore(".js-custom-pricing-period-add-input");
        });
    };

    const reAssignNewIndex = (target) => {
        let $groupInput = target.find(".group-input");

        $groupInput.each((index, $gp) => {
            $($gp)
                .find("#custom_period")
                .attr("name", `custom_price[${index}][periods]`);
            $($gp)
                .find("#custom_period_time")
                .attr("name", `custom_price[${index}][periodic]`);
            $($gp)
                .find("#custom_price_exclude")
                .attr("name", `custom_price[${index}][price_exclude]`);
        });

        const $btnAdd = target.find(".js-custom-pricing-period-add-input");
        $btnAdd.data("index", $btnAdd.data("index") - 1);
    };

    init();
};

export default customPricingPeriod;
