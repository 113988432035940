import $ from "jquery";

const modalNestedTrigger = () => {
    const init = () => {
        button();
    };

    const button = () => {
        const el = {
            buttonTrigger: ".js-modal-nested-trigger",
            modal: ".js-modal",
            modalDialog: ".js-modal-dialog",
            modalCard: ".modal-card",
            close: ".close-nested",
        };

        if ($(el.buttonTrigger).length === 0) return;
        $(el.buttonTrigger).on("click", async function () {

            $(el.modal).addClass("active");
            $(el.modalCard).addClass("opacity-semi");

            let currentTab = $(this).attr("data-target");
            $(`.${currentTab}`).addClass("active");
            $(`.${currentTab}`).addClass("opacity-1");


            $(el.close).on("click", function () {
                $(`.${currentTab}`).removeClass("active");
                $(`.${currentTab}`).removeClass("opacity-semi-false");
                $(el.modalCard).removeClass("opacity-semi");
            });
            $(el.modalDialog).on("click", function () {
                $(`.${currentTab}`).removeClass("active");
                $(`.${currentTab}`).removeClass("opacity-semi-false");
                $(el.modalCard).removeClass("opacity-semi");
            });
        });


    };

    init();
};

export default modalNestedTrigger;
