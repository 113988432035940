const init = () => {
    searchStatus();
    searchId();
};

const status = {
    0: "Draft invoice",
    2: "Unpaid", //Sent
    3: "Paid in part",
    4: "Paid",
    8: "Credit invoice",
    9: "Cancelled",
};

const searchStatus = () => {
    const selectEl = document.querySelector("#selectInvoiceBtn");
    if (selectEl) {
        selectEl.addEventListener("change", async (e) => {
            const response = await fetch(
                `/invoiceStatus?status=${
                    e.target.selectedIndex > 0
                        ? e.target.options[e.target.selectedIndex].value
                        : ""
                }`
            );

            const invoices = await response.json();

            console.log(invoices.totalresults);

            let tableResult = ``;

            const tableBody = document.querySelector(
                ".invoices-table-content tbody"
            );

            const totalElement = document.querySelector(".all-invoice .total");

            if (response.ok && invoices["invoices"] != undefined) {
                invoices["invoices"].forEach((invoice) => {
                    let statusLable = ``;
                    if (invoice["Status"] == 2) {
                        statusLable += `<div class="label label-deactive">
                                                ${status[invoice["Status"]]}
                                            </div>`;
                    } else {
                        statusLable += `<div class="label label-active">
                                                ${status[invoice["Status"]]}
                                            </div>`;
                    }

                    tableResult += `<tr>
                        <td>${invoice["InvoiceCode"]}</td>
                        <td>${invoice["Date"]}</td>
                        <td>${invoice["AmountIncl"]}</td>
                        <td>
                            ${statusLable}
                        </td>
                        <td> <a href=" /invoice/${invoice["InvoiceCode"]} ">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1574_40613)">
                                <path d="M0.666656 8.00033C0.666656 8.00033 3.33332 2.66699 7.99999 2.66699C12.6667 2.66699 15.3333 8.00033 15.3333 8.00033C15.3333 8.00033 12.6667 13.3337 7.99999 13.3337C3.33332 13.3337 0.666656 8.00033 0.666656 8.00033Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.99999 10.0003C9.10456 10.0003 9.99999 9.10489 9.99999 8.00033C9.99999 6.89576 9.10456 6.00033 7.99999 6.00033C6.89542 6.00033 5.99999 6.89576 5.99999 8.00033C5.99999 9.10489 6.89542 10.0003 7.99999 10.0003Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_1574_40613">
                                <rect width="16" height="16" fill="currentColor"/>
                                </clipPath>
                                </defs>
                            </svg>
                            Detail</a>
                        </td>
                    </tr>`;
                });

                if (tableBody && totalElement) {
                    totalElement.innerText = invoices["totalresults"];
                    tableBody.innerHTML = tableResult;
                }
            } else {
                tableResult += `<td colspan="5"> there is no match invoice with mathed parameter </td>`;
                totalElement.innerText = invoices.totalresults;
                tableBody.innerHTML = tableResult;
            }
        });
    }
};

const searchId = () => {
    const searchBtn = document.querySelector("#searchInvoiceBtn");
    var searchTimer;
    if (searchBtn) {
        searchBtn.addEventListener("input", (e) => {
            clearTimeout(searchTimer);
            searchTimer = setTimeout(async () => {
                const response = await fetch(
                    `/invoiceSearch?parameter=${
                        e.target.value != "" ? e.target.value : ""
                    }`
                );

                let tableResult = ``;

                const tableBody = document.querySelector(
                    ".invoices-table-content tbody"
                );

                const totalElement = document.querySelector(
                    ".all-invoice .total"
                );

                const result = await response.json();

                if (response.ok) {
                    result["invoices"].forEach((invoice) => {
                        let statusLable = ``;
                        if (invoice["Status"] == 2) {
                            statusLable += `<div class="label label-deactive">
                                                ${status[invoice["Status"]]}
                                            </div>`;
                        } else {
                            statusLable += `<div class="label label-active">
                                                ${status[invoice["Status"]]}
                                            </div>`;
                        }

                        tableResult += `<tr>
                        <td>${invoice["InvoiceCode"]}</td>
                        <td>${invoice["Date"]}</td>
                        <td>€ ${invoice["AmountIncl"]}</td>
                        <td>
                            ${statusLable}
                        </td>
                        <td> <a href=" /invoice/${invoice["InvoiceCode"]} ">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1574_40613)">
                                <path d="M0.666656 8.00033C0.666656 8.00033 3.33332 2.66699 7.99999 2.66699C12.6667 2.66699 15.3333 8.00033 15.3333 8.00033C15.3333 8.00033 12.6667 13.3337 7.99999 13.3337C3.33332 13.3337 0.666656 8.00033 0.666656 8.00033Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.99999 10.0003C9.10456 10.0003 9.99999 9.10489 9.99999 8.00033C9.99999 6.89576 9.10456 6.00033 7.99999 6.00033C6.89542 6.00033 5.99999 6.89576 5.99999 8.00033C5.99999 9.10489 6.89542 10.0003 7.99999 10.0003Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_1574_40613">
                                <rect width="16" height="16" fill="currentColor"/>
                                </clipPath>
                                </defs>
                            </svg>
                            Detail</a>
                        </td>
                    </tr>`;
                    });

                    if (tableBody && totalElement) {
                        totalElement.innerText = result["totalresults"];
                        tableBody.innerHTML = tableResult;
                    }
                } else {
                    tableResult += `<td colspan="6">${result.message}</td>`;
                    totalElement.innerText = result.data["totalresults"];
                    tableBody.innerHTML = tableResult;
                }
            }, 200);
        });
    }
};

export default {
    init,
};
