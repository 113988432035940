import $ from "jquery";

const chooseService = () => {
    const init = () => {
        menu();
    };

    const menu = () => {
        const el = {
            menuItem: ".js-service-menu-item",
            colForm: ".js-row-service",
            otherForm: ".js-row-billing-information",
            otherForm2: "#hostingBilling",
        };

        if ($(el.menuItem).length === 0) return;
        $(el.menuItem).on("click", function () {
            $(el.menuItem).removeClass("active");
            $(this).addClass("active");
            // $(el.otherForm).addClass("active");

            let currentTab = $(this).attr("data-target");

            $(el.colForm).removeClass("active");
            $(`#${currentTab}`).addClass("active");

            if (`${currentTab}` == "formHosting") {
                $(el.otherForm2).addClass("active");
            } else {
                $(el.otherForm2).removeClass("active");
            }
        });
    };

    init();
};

export default chooseService;
