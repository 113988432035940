import $ from "jquery";

const showServiceBill = () => {
    const init = () => {
        menu()
    };

    const menu = () => {
        const el = {
            toggleBtn: "#service-checkbox",
            billingInfo: '.js-row-billing-information',
        };

        if ($(el.toggleBtn).length === 0) return;
        $(el.toggleBtn).on('click', function(){
            if( $(this).is(':checked') ){
                $(el.billingInfo).addClass('active')
            }else{
                $(el.billingInfo).removeClass('active')
            }
        })
    };

    init();
};

export default showServiceBill;
