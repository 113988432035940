import $ from "jquery";

const showIPField = () => {
    const init = () => {
        menu()
    };

    const menu = () => {
        const el = {
            toggleBtn: ".js-ip-toggle",
            field: '.js-ip',
        };

        if ($(el.toggleBtn).length === 0) return;
        $(el.toggleBtn).on('click', function(){
            if( $(this).is(':checked') ){
                $(el.field).addClass('active')
            }else{
                $(el.field).removeClass('active')
            }
        })
    };

    init();
};

export default showIPField;
