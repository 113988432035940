import $ from "jquery";

const domainInformation = () => {
    const init = () => {
        menu()
    };

    const menu = () => {
        const el = {
            menuItem: ".js-menu-item.domain",
            colForm: '.js-domain-information-forms'
        };

        if ($(el.menuItem).length === 0) return;
        $(el.menuItem).on('click', function(){
            // Check for active menu
            $(el.menuItem).removeClass('active');
            $(this).addClass('active');

            // Display active tab
            let currentTab = $(this).attr('data-target');
            
            $(el.colForm).removeClass('active');
            $(`#${currentTab}`).addClass('active');
        })
    };

    init();
};

export default domainInformation;
