const init = () => {
    statusChange();
    searchClient();
    checkDomain();
    dnsTemplateDetail();
    sanitizeCheckDomain();
    changeBillService();
    addContactCustomer();
    // getProduct();
};

const statusChange = () => {
    const selectDomainBtn = document.querySelector("#selectDomainBtn");
    if (selectDomainBtn) {
        selectDomainBtn.addEventListener("change", async (e) => {
            const value =
                e.target.options[e.target.selectedIndex].value != ""
                    ? e.target.options[e.target.selectedIndex].value
                    : "all";

            const response = await fetch(`/domainByStatus?status=${value}`);

            let tableResult = ``;

            const tableBody = document.querySelector(
                ".domains-table-content tbody"
            );

            const totalElement = document.querySelector(".all-domain .total");

            if (response.ok) {
                const result = await response.json();

                if (result["results"]) {
                    result["results"].forEach((domain) => {
                        let date = new Date(domain.renewal_date);
                        tableResult += `<tr>
                            <td>${domain.domain.name}.${
                            domain.domain.extension
                        }</td>
                            <td>${date.getDate()}/${date.getMonth()}/${date.getFullYear()}</td>
                            <td>${
                                domain.status == "ACT"
                                    ? "<span>Active</span>"
                                    : "<span>Deactive</span>"
                            }</td>
                            <td> <a href=" /domain/${
                                domain.id
                            } ">detail</a> </td>
                            <td> <a href=" /domain/nameserver/${
                                domain.id
                            } ">Edit nameserver</a> </td>
                            <td> <a href=" /invoice/information/${
                                domain.id
                            } ">Edit Information</a> </td>
                        </tr>`;
                    });

                    totalElement.innerText = result["total"];
                    tableBody.innerHTML = tableResult;
                } else {
                    tableResult += `<td colspan="6">there is no domain match with your parameter</td>`;
                    totalElement.innerText = 0;
                    tableBody.innerHTML = tableResult;
                }
            }
        });
    }
};

const searchClient = () => {
    const searchClientSelect = document.querySelector("#searchClient");
    const searchClientButton = document.querySelector("#searchClientButton");
    const searchClientInformation = document.querySelector(".js-client-information");
    const chooseServiceRow = document.querySelector(".js-row-choose-service");

    if (searchClientSelect) {
        searchClientSelect.addEventListener("change", async (e) => {
            const response = await fetch(
                `/user/${e.target.value != "" ? e.target.value : "all"}`
            );

            if (response.ok) {
                const result = await response.json();

                document.querySelector(".js-company-name").innerText =
                    result["CompanyName"];
                document.querySelector(
                    ".js-name"
                ).innerText = `${result["Initials"]} ${result["SurName"]}`;
                document.querySelector(".js-address").innerText =
                    result["Address"];
                document.querySelector(
                    ".js-city-zipcode"
                ).innerText = `${result["ZipCode"]} ${result["City"]}`;
                document.querySelector(".js-country").innerText =
                    result["Country"];

                const clientInput = document.querySelectorAll(
                    'input[name="client_id"]'
                );

                [...clientInput].forEach((inputEl) => {
                    inputEl.value = e.target.value;
                });

                searchClientInformation.classList.remove('d-none');

                searchClientButton.addEventListener('click', () => {
                    chooseServiceRow.classList.remove('d-none');
                })
            }
        });
    }
};

const checkDomain = () => {
    const checkDomainBtn = document.querySelector("#checkDomain");
    const domainStatusEl = document.querySelector(".js-domain-status");
    const loadingSpin = document.querySelector(".js-loading-spin-domain");
    if (checkDomainBtn) {
        checkDomainBtn.addEventListener("click", async () => {

            domainStatusEl.classList.add('d-none');

            loadingSpin.classList.remove('d-none');

            const domainName = document.querySelector(
                "#search_domain_name"
            ).value;

            if (domainName == "") {
                return alert("You need to fill domain name");
            }
            let domainExtension = document.querySelector(
                "#select_domain_extension"
            );

            domainExtension =
                domainExtension.options[domainExtension.selectedIndex].value;

            const token = document.querySelector('input[name="_token"]').value;

            const data = {
                domain_name: domainName,
                domain_extension: domainExtension,
                _token: token,
            };

            const response = await fetch("/domainCheck", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const result = await response.json();

                domainStatusEl.classList.remove('d-none');
                loadingSpin.classList.add('d-none');

                

                if(domainStatusEl.innerText = result.data.results[0].status == "free"){
                    domainStatusEl.innerText =  "Available";
                    domainStatusEl.style.color = '#13A6DF';
                }else{
                    domainStatusEl.innerText =  "Taken";
                    domainStatusEl.style.color = '#E6007E';
                }

                const authCodeEl = document.querySelector(
                    ".js-authorization-code"
                );
                authCodeEl.style.display =
                    result.data.results[0].status == "free" ? "none" : "block";
            }
        });
    }
};

const addContactCustomer = () => {
    const btnAddContactCustomer = document.querySelector(
        ".js-contact-add-customer"
    );

    if (btnAddContactCustomer) {
        btnAddContactCustomer.addEventListener("click", async (e) => {
            e.preventDefault();
            const form = document.getElementById("domain_contact");
            const fd = new FormData(form);
            const csrf = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content");

            fd.append("_token", csrf);

            const response = await fetch("/customer", {
                method: "POST",
                body: fd,
            });

            if (response.ok) {
                const result = await response.json();

                if (result?.data?.handle) {
                    const radioDomainOwner = document.querySelector(
                        '[name="domain_owner"]#yes'
                    );

                    const radioAdmin = document.querySelector(
                        '[name="admin_contact"]#yes'
                    );

                    const radioTech = document.querySelector(
                        '[name="tech_contact"]#yes'
                    );

                    radioDomainOwner.value = result.data.handle;
                    radioAdmin.value = result.data.handle;
                    radioTech.value = result.data.handle;
                } else {
                    alert("somting went wrong please contact service");
                }
            }
        });
    }
};

const changeBillService = () => {
    const billCheckBox = document.querySelector("#service-checkbox");
    if (billCheckBox) {
        billCheckBox.addEventListener("change", (e) => {
            let isBilled = "";

            isBilled = document.querySelectorAll(`input[name="is_billed"]`);

            [...isBilled].forEach((isBill) => {
                if (e.target.checked) {
                    isBill.value = "1";
                } else {
                    isBill.value = "0";
                }
            });
        });
    }
};

const dnsTemplateDetail = () => {
    const selectDnsTemplate = document.querySelector("#dns_template");
    if (selectDnsTemplate) {
        selectDnsTemplate.addEventListener("change", async (e) => {
            const templateId = e.target.options[e.target.selectedIndex].value;

            if (templateId == "") {
                for (let index = 1; index <= 3; index++) {
                    const nameserverEl = document.querySelector(
                        `#name_server_${index}`
                    );

                    nameserverEl.innerText = "";
                    nameserverEl.value = "";
                }
            }

            if (templateId != "") {
                const response = await fetch(
                    `/domain/dns/template/${templateId}`
                );

                if (response.ok) {
                    const result = await response.json();

                    const nameservers = result["records"];
                    let ns_key = 0;
                    nameservers.forEach((nameserver) => {
                        if (nameserver.type == "NS") {
                            ns_key += 1;
                            const nameserverEl = document.querySelector(
                                `#name_server_${ns_key}`
                            );
                            const ipEl = document.querySelector(
                                `#ip_${ns_key}`
                            );

                            nameserverEl.value = nameserver.value;
                            ipEl.value = nameserver.ip ?? "";
                        }
                    });
                }
            }
        });
    }
};

const sanitizeCheckDomain = () => {
    const checkDomainInput = document.querySelector("#searh_domain_name");
    if (checkDomainInput) {
        checkDomainInput.addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
        });
    }
};

// const getProduct = () => {
//     const productSelectEl = document.querySelector("#searchProduct");
//     if (productSelectEl) {
//         productSelectEl.addEventListener("change", async (e) => {
//             const value = e.target.options[e.target.selectedIndex].value;
//             const url = "/domain";

//             const response = await fetch(url);
//             if (response.ok) {
//                 const result = response.json();

//                 console.log(result);
//             }
//         });
//     }
// };

export default {
    init,
};
