import $ from "jquery";

const manageServiceSideMenu = () => {
    const init = () => {
        menu();
    };

    const menu = () => {
        const el = {
            dropdownTrigger: ".js-show-hide-dropdown",
            dropdownIcon: ".js-show-hide-dropdown-ic",
            dropdownContent: ".js-dropdown-content",
        };

        if ($(el.dropdownTrigger).length === 0) return;
        $(el.dropdownTrigger).on("click", function () {
            $(this).children($(el.dropdownIcon)).toggleClass('active');
            let currentContent = $(this).attr("data-target");
            $(`#${currentContent}`).toggleClass('active');

        });
    };

    init();
};

export default manageServiceSideMenu;
