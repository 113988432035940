const period = ["d", "w", "m", "k", "h", "j", "t"];

const billingPeriodChange = () => {
    const billingPeriodEls = document.querySelectorAll(
        "select[name='billing_cycle_per']"
    );

    if (billingPeriodEls.length > 0) {
        [...billingPeriodEls].forEach((billingPeriodEl) => {
            billingPeriodEl.addEventListener("change", (e) => {
                const option = e.target.options[e.target.selectedIndex];
                const parent = e.target.closest(".new-profile");

                const periodicTextEl = parent.querySelector(".periodic-text");

                const billingLength = parent.querySelector(
                    "input[name='billing_cycle_length']"
                );
                const billingEndDateEl = parent.querySelector(".end-date");
                const billingStartDateEl = parent.querySelector(
                    "input[name='billing_period_start']"
                );

                periodicTextEl.innerText = option.innerText;

                const billingLengthVal = parseInt(billingLength.value);
                const billingStartDate = billingStartDateEl.value;
                const date = new Date(billingStartDate);

                const endDate = parseDate(option.value, billingLengthVal, date);
                // if (option.value == "d") {
                //     endDate = new Date(
                //         date.setDate(date.getDate() + billingLengthVal)
                //     );
                // } else if (option.value == "w") {
                //     endDate = new Date(
                //         date.setDate(date.getDate() + billingLengthVal * 7)
                //     );
                // } else if (option.value == "m") {
                //     endDate = new Date(
                //         date.setMonth(date.getMonth() + billingLengthVal)
                //     );
                // } else if (option.value == "k") {
                //     endDate = new Date(
                //         date.setMonth(date.getMonth() + billingLengthVal * 3)
                //     );
                // } else if (option.value == "h") {
                //     endDate = new Date(
                //         date.setMonth(date.getMonth() + billingLengthVal * 6)
                //     );
                // } else if (option.value == "j") {
                //     endDate = new Date(
                //         date.setFullYear(date.getFullYear() + billingLengthVal)
                //     );
                // } else if (option.value == "t") {
                //     endDate = new Date(
                //         date.setFullYear(
                //             date.getFullYear() + billingLengthVal * 2
                //         )
                //     );
                // }
                endDateSetter(billingEndDateEl, endDate);
            });
        });
    }
};

const billingPeriodLengthChange = () => {
    const billingLengthEls = document.querySelectorAll(
        'input[name="billing_cycle_length"]'
    );
    if (billingLengthEls.length > 0) {
        [...billingLengthEls].forEach((billingLengthEl) => {
            billingLengthEl.addEventListener("input", (e) => {
                const value = parseInt(e.target.value);
                const parent = e.target.closest(".new-profile");

                const productPriceEl = parent.querySelector(
                    'select[name="search_product"]'
                );
                const product =
                    productPriceEl.options[productPriceEl.selectedIndex]
                        .dataset;
                const billingEndDateEl = parent.querySelector(".end-date");
                const billingStartDateEl = parent.querySelector(
                    "input[name='billing_period_start']"
                );
                const billingPeriodEl = parent.querySelector(
                    'select[name="billing_cycle_per"]'
                );

                if (
                    billingPeriodEl.value == "" ||
                    billingPeriodEl.value == "empty"
                )
                    return;

                const billingStartDate = billingStartDateEl.value;
                const date = new Date(billingStartDate);

                const endDate = parseDate(billingPeriodEl.value, value, date);

                // billingEndDateEl.valueAsDate = endDate;
                // $(billingEndDateEl).val(`${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? `0${endDate.getMonth() + 1}` : endDate.getMonth() + 1 }-${endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate() }`);
                endDateSetter(billingEndDateEl, endDate);

                const billingPrice = setPrice(
                    product.priceExclude,
                    product.period,
                    billingPeriodEl.value
                );
            });
        });
    }
};

const billingPeriodStartDateChange = () => {
    const billingStartDateEls = document.querySelectorAll(
        "input[name='billing_period_start']"
    );
    if (billingStartDateEls.length > 0) {
        [...billingStartDateEls].forEach((billingStartDateEl) => {
            billingStartDateEl.addEventListener("input", (e) => {
                const date = new Date(e.target.value);
                const parent = e.target.closest(".new-profile");

                const billingEndDateEl = parent.querySelector(".end-date");
                const billingLengthEl = parent.querySelector(
                    'input[name="billing_cycle_length"]'
                );
                const billingPeriodEl = parent.querySelector(
                    'select[name="billing_cycle_per"]'
                );

                if (
                    billingPeriodEl.value == "" ||
                    billingPeriodEl.value == "empty"
                )
                    return;

                const billingLength = billingLengthEl.value;
                const value = parseInt(billingLength);

                const endDate = parseDate(billingPeriodEl.value, value, date);

                endDateSetter(billingEndDateEl, endDate);
            });
        });
    }
};

const parseDate = (billingPer, billingLength, date) => {
    let endDate = new Date();

    const cycles = {
        d: 1,
        w: 7,
        m: 1,
        k: 3,
        h: 6,
        j: 1,
        t: 2,
    };

    if (billingPer == "d" || billingPer == "w") {
        endDate = new Date(
            date.setDate(date.getDate() + billingLength * cycles[billingPer])
        );
    } else if (billingPer == "m" || billingPer == "k" || billingPer == "h") {
        let nextMonth =
            date.getMonth() + 1 + billingLength * cycles[billingPer];

        endDate =
            date.getDate() <
            new Date(date.getFullYear(), nextMonth, 0).getDate()
                ? new Date(
                      date.setMonth(
                          date.getMonth() + billingLength * cycles[billingPer]
                      )
                  )
                : new Date(date.getFullYear(), nextMonth, 0);
    } else if (billingPer == "j" || billingPer == "t") {
        endDate = new Date(
            date.setFullYear(
                date.getFullYear() + billingLength * cycles[billingPer]
            )
        );
    }

    // if (billingPer == "d") {
    // endDate = new Date(date.setDate(date.getDate() + billingLength));
    // } else if (billingPer == "w") {
    //     endDate = new Date(date.setDate(date.getDate() + billingLength * 7));
    // } else if (billingPer == "m") {
    //     endDate = new Date(date.setMonth(date.getMonth() + billingLength));
    // } else if (billingPer == "k") {
    //     endDate = new Date(date.setMonth(date.getMonth() + billingLength * 3));
    // } else if (billingPer == "h") {
    //     endDate = new Date(date.setMonth(date.getMonth() + billingLength * 6));
    // } else if (billingPer == "j") {
    //     endDate = new Date(
    //         date.setFullYear(date.getFullYear() + billingLength)
    //     );
    // } else if (billingPer == "t") {
    //     endDate = new Date(
    //         date.setFullYear(date.getFullYear() + billingLength * 2)
    //     );
    // }

    return endDate;
};

const endDateSetter = (endDateEl, endDate) => {
    if (isNaN(endDate.getMonth())) {
        $(endDateEl).html("");
    } else {
        $(endDateEl).html(
            `${
                endDate.getMonth() + 1 < 10
                    ? `0${endDate.getMonth() + 1}`
                    : endDate.getMonth() + 1
            }/${
                endDate.getDate() < 10
                    ? `0${endDate.getDate()}`
                    : endDate.getDate()
            }/${endDate.getFullYear()}`
        );
    }
    if (endDateEl.innerText.length !== 0) {
        endDateEl.classList.add("style");
    } else {
        endDateEl.classList.remove("style");
    }
};

const arryPeriodDay = {
    d: 1,
    w: 7,
    m: 30,
    k: 90,
    h: 180,
    j: 365,
    t: 730,
};

const setPrice = (productPrice, productPeriod, billingPeriod) => {
    return (
        (productPrice / arryPeriodDay[productPeriod]) *
        arryPeriodDay[billingPeriod]
    );
};

const setTax = (e) => {};

const init = () => {
    billingPeriodChange();
    billingPeriodLengthChange();
    billingPeriodStartDateChange();
};

export default {
    init,
};
