import $ from "jquery";
// import select2 from "select2";

const init = () => {
    // displayProductTypeForm();
    // initselect2();
    tldChange();
};

// select2(window, $);

// const displayProductTypeForm = () => {
//     const productTypeSelector = document.querySelectorAll(
//         ".js-service-menu-item.js-product-form"
//     );
//     const productForms = document.querySelectorAll(".product-form");

//     if (productForms.length > 0 && productTypeSelector.length > 0) {
//         [...productTypeSelector].forEach((el) => {
//             el.addEventListener("click", (e) => {
//                 const target = e.target.dataset.target;
//                 if (target) {
//                     [...productForms].forEach((pf) => {
//                         if (pf.getAttribute("id") == target) {
//                             pf.style.display = "block";
//                         } else {
//                             pf.style.display = "none";
//                         }
//                     });
//                 }
//             });
//         });
//     }
// };

const initselect2 = () => {
    $(function () {
        $("#searchProductBtn").select2();
    });
};

const tldChange = () => {
    const productDomainTldEl = document.querySelector("#product_domain_tld");
    if (productDomainTldEl) {
        productDomainTldEl.addEventListener("input", async (e) => {
            console.log(e.target.value);

            // const tldDetail =
        });
    }
};

export default {
    init,
};
