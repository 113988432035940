const init = () => {
    generateRandomPassword();
};

const generateRandomPassword = () => {
    const buttonRandomPassword = document.querySelector(
        "#generateRandomPassword"
    );
    if (buttonRandomPassword) {
        buttonRandomPassword.addEventListener("click", () => {
            const randomPass = randomPassword();
            setPassword(randomPass);
        });
    }
};

const setPassword = (password) => {
    const passwordEl = document.querySelector("#currentPassword");
    if (passwordEl) {
        passwordEl.value = password;
    }
};

const randomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-10);
    return randomPassword;
};

export default {
    init,
};
