import authSlick from "./authSlick";
import lang from "./lang";
import invoice from "./Invoices";
import addClientMenu from "./addClientMenu";
import domain from "./Domain";
import chooseService from "./chooseService";
import domainInformation from "./domainInformation";
import hostingInformation from "./hostingInformation";
import billing from "./billing";
import hosting from "./hosting";
import showServiceBill from "./showServiceBill";
import showIPField from "./showIPField";
import modalTrigger from "./modalTrigger";
import modalNestedTrigger from "./modalNestedTrigger";
import cardCompanyDetail from "./cardCompanyDetail";
import product from "./product";
import financial from "./financial";
import customPricingPeriod from "./customPricingPeriod";
import user from "./Users";
import manageServiceSideMenu from "./manageServiceSideMenu";
import billingPeriod from "./billingPeriod";
import manageServiceEditMenu from "./js-display/manageServiceEditMenu";
import displayCreateDomainContractData from "./js-display/createDomainContractData";
import createDomainContractData from "./createDomainContractData";

lang();
authSlick();
addClientMenu();
chooseService();
domainInformation();
hostingInformation();
showServiceBill();
showIPField();
billing();
modalTrigger();
modalNestedTrigger();
cardCompanyDetail();
financial();
customPricingPeriod();
manageServiceSideMenu();
manageServiceEditMenu();
displayCreateDomainContractData();

createDomainContractData();

invoice.init();
domain.init();
hosting.init();
product.init();
user.init();
billingPeriod.init();
