import $ from "jquery";

const displayCreateDomainContractData = () => {
    const init = () => {
        contractPeriod();
    }

    const contractPeriod = () =>{

        const el = {
            radioTrigger: '.contract-data-radio',
            content: '.js-period-contract, .js-period-billing',
        }

        if($(el.radioTrigger).length === 0  && $(el.content).length === 0) return;
        $(el.radioTrigger).on('click', (e) =>{
            const parent = $(e.target.closest('.card-contract-data'));
            if($(e.target).hasClass('no'))
            {
                parent.find(el.content).removeClass('d-none');
            }else{
                parent.find(el.content).addClass('d-none');
            }
        })

        

    }

    init();
}


export default displayCreateDomainContractData;