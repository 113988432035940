import $ from "jquery";

const financial = () => {
    const init = () => {
        menu();
        productFinancialChange();
        taxFinancialChange();
    };

    const menu = () => {
        const el = {
            selectItem: ".price-per",
            card: ".js-card-custom-pricing-period",
        };

        if ($(el.selectItem).length === 0) return;
        $(el.selectItem).on("click", function () {
            $(this).on("click", function () {
                if ($(this).find(":selected").val() !== "empty") {
                    $(el.card).addClass("active");
                } else {
                    $(el.card).removeClass("active");
                }
            });
        });
    };

    const productFinancialChange = () => {
        // const priceExcludeEl = document.querySelector("#price_exclude");
        // const priceIncludeEl = document.querySelector(".product_incl_tax");

        const tax = document.querySelector("#tax");

        const productFinancialEls = document.querySelectorAll(
            "#searchProductFinancial"
        );
        if (productFinancialEls.length > 0) {
            [...productFinancialEls].forEach((productFinancialEl) => {
                productFinancialEl.addEventListener("change", (e) => {
                    const target = e.target.closest(".input-container.form");

                    const priceExcludeEl = target.querySelector(
                        'input[name="price_exclude"]'
                    );
                    const priceIncludeEl =
                        target.querySelector(".product_incl_tax");

                    const taxValue =
                        tax.options[tax.selectedIndex].dataset.rate;
                    const priceExclude =
                        e.target.options[e.target.selectedIndex].dataset
                            .priceExclude;
                    const priceInclTax =
                        parseInt(priceExclude) +
                        priceExclude * parseFloat(taxValue);

                    priceExcludeEl.value = parseInt(priceExclude);
                    priceIncludeEl.innerText = priceInclTax;

                    const period =
                        e.target.options[e.target.selectedIndex].dataset.period;
                    setPeriodiBillingPeriod(period, e);
                });
            });
        }
    };

    const taxFinancialChange = () => {
        const taxFinancialEls = document.querySelectorAll('select[name="tax"]');

        if (taxFinancialEls.length > 0) {
            [...taxFinancialEls].forEach((taxFinancialEl) => {
                taxFinancialEl.addEventListener("change", (e) => {
                    const target = e.target.closest(".input-container.form");
                    const priceIncludeEl =
                        target.querySelector(".product_incl_tax");
                    const taxFinancialEl = target.querySelector("#tax");

                    const taxValue =
                        e.target.options[e.target.selectedIndex].dataset.rate;
                    const priceExclude =
                        target.querySelector("#price_exclude").value;

                    if (!priceExclude) return;

                    const priceIncludeTax =
                        parseInt(priceExclude) +
                        parseInt(priceExclude) * parseFloat(taxValue);
                    priceIncludeEl.innerText = priceIncludeTax;
                });
            });
        }
    };

    const setPeriodiBillingPeriod = async (period, event) => {
        const response = await fetch(`/availablePeriod?period=${period}`);
        if (response.ok) {
            const result = await response.json();

            const billingPeriodEl = event.target
                .closest(".js-billing-forms")
                .querySelector('select[name="billing_cycle_per"]');

            [...billingPeriodEl.options].forEach((option, i) => {
                option.remove();
            });

            [...Object.entries(result)].forEach((period, i) => {
                const option = document.createElement("option");
                option.value = period[0];
                option.innerText = period[1];
                // += `<option value="${period[0]}"> ${period[1]} </option>`;
                billingPeriodEl.appendChild(option);
            });
        }
    };

    init();
};

export default financial;
