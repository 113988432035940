import $ from "jquery";

const cardCompanyDetail = () => {
    const init = () => {
        action()
    };

    const action = () => {
        const el = {
            trigger: ".js-card-company-detail",
            field: '.js-card-company-detail-field'
        };

        if ($(el.trigger).length === 0) return;
        $(el.trigger).on('click', function(){
            $(el.trigger).toggle();
            $(el.field).toggle();
        })
    };

    init();
};

export default cardCompanyDetail;
