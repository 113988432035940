import "./bootstrap";
import "laravel-datatables-vite";

import Alpine from "alpinejs";
import User from "./customs/Users";
import "./customs/_customs";

window.Alpine = Alpine;

Alpine.start();
User.init();
