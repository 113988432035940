import $ from "jquery";

const lang = () => {
    const init = () => {
        handleClick();
    };

    const handleClick = () => {
        const el = {
            lists: ".js-lang-list",
            listAll: ".js-lang-list li",
            listNotActive: ".js-lang-list li:not(.active)",
            listActive: ".js-lang-list li.active",
        };

        if ($(el.lists).length === 0) return;

        $(el.listNotActive).hide();

        $(document).on("click", el.listActive, function () {
            $(el.listNotActive).toggle(300);
        });

        $(document).on("click", el.listNotActive, function () {
            $(el.listActive).toggle(300);
            $(el.listAll).removeClass("active");
            $(this).addClass("active");
        });
    };

    init();
};

export default lang;
