import $ from "jquery";
import { find } from "lodash";

const createDomainContractData = () =>{
    const init = () => {
        changeForms();
    }

    
    const changeForms = () =>{
        const el = {   
            contractLength: '.contract-length',
            contractPer: '.contract-per',
            contractStartDate: '.contract-date-start',
            contractEndDate: '.contract-date-end',
        }

        const arrayCycles ={
            d: 1,
            w: 7,
            m: 1,
            k: 3,
            h: 6,
            j: 1,
            t: 2,
        }
        
        if($(el.contractLength).length === 0) return;

        const parseDate = (contractPer, contractLength, date) => {
            let endDate = new Date();
        
            if (contractPer == "d" || contractPer == "w") {
                endDate = new Date(date.setDate(date.getDate() + contractLength * arrayCycles[contractPer]));
            }else if(contractPer == "m" || contractPer == "k" || contractPer == "h"){
                
                let nextMonth = date.getMonth() + 1 + contractLength * arrayCycles[contractPer];
            
                endDate = date.getDate() < new Date(date.getFullYear(), nextMonth, 0).getDate() ? 
                new Date(date.setMonth(date.getMonth() + contractLength * arrayCycles[contractPer])) :
                new Date(date.getFullYear(), nextMonth, 0);
            
            }else if(contractPer == "j" || contractPer == "t"){
                endDate = new Date(date.setFullYear(date.getFullYear() + contractLength * arrayCycles[contractPer]));
            }
        
            return endDate;
        };

        const endDateSetter = (endDateEl, endDate) => {
            if(isNaN(endDate.getMonth())){
                endDateEl.html('');
                endDateEl.removeClass('style');
            }else{
                endDateEl.html(`${endDate.getMonth() + 1 < 10 ? `0${endDate.getMonth() + 1}` 
                : endDate.getMonth() + 1 }/${endDate.getDate() < 10 ? `0${endDate.getDate()}` 
                : endDate.getDate() }/${endDate.getFullYear()}`);
                endDateEl.addClass('style');
            }
        }

        const localValues = function(parentEl){
            const valueLength = parseInt(parentEl.find(el.contractLength).val());
            const valueStartDate = parentEl.find(el.contractStartDate).val();
            const valuePer = parentEl.find(el.contractPer).val();
            const elEndDate = parentEl.find(el.contractEndDate);

            return {
                valueLength,
                valueStartDate,
                valuePer,
                elEndDate,
            }
        }

        $(`.${$(el.contractLength).attr('class')}, 
            .${$(el.contractPer).attr('class')}, 
            .${$(el.contractStartDate).attr('class')}`)
            .on('input change', (e) =>{

            const parent = $(e.target.closest('.card-contract-data'));
            
            const values = localValues(parent);

            const valueStartDate = new Date(values.valueStartDate);

            const valueEndDate = parseDate(values.valuePer, values.valueLength, valueStartDate);

            endDateSetter(values.elEndDate, valueEndDate);
        })



    }

    init();
}

export default createDomainContractData;
