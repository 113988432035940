import $ from "jquery";
import 'slick-carousel';

const authSlick = () => {
    const init = () => {
        slick();
    };

    const slick = () => {
        const el = {
            authPost: ".js-auth__posts",
        };

        if ($(el.authPost).length === 0) return;
        $(el.authPost).slick({
            dots: true,
            customPaging: function(slider, i) {
                return '<div class="post-dots"></div>';
            },
            arrows: false,
            autoplay: true,
        });
    };

    init();
};

export default authSlick;
